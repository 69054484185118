
.overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
}
.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 50%;
  position: relative;
  transition: all 5s ease-in-out;
}


.about-popup {
  margin: 5vh auto;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  background: #fff;
  border-radius: 5px;
  width: 80%;
  position: relative;
  transition: all 5s ease-in-out;
}

.about-popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.about-content {
  font-size: max(16px, 1.5vw);
}
.about-popup .close:hover {
  cursor: pointer;
  color: #000;
}
.about-popup .content {
  max-height: 70%;
  overflow: auto;
}

.title {
  padding-top: 25px;
  padding-bottom: 10px;
}

.about-title {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: max(20px, 2vw);
}

.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  cursor: pointer;
  color: #000;
}
.popup .content {
  max-height: 70%;
  overflow: auto;
}

.popup-btn {
  background-color: rgb(85, 122, 149, 0.2) !important;
  margin: 0 auto;
  display: block;
}

.btn-in-pop {
  width: 100%;
}

.popup-btn:hover {
  color: black !important;
  background-color: rgb(85, 122, 149) !important;
}

.popup-btn:active {
  color: black !important;
  background-color: white !important;
}
 
@media screen and (max-width: 700px) {
  .popup {
    width: 70%;
  }
}