@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Lora', ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.name {
    position: absolute;
    top: 30%;
    left: 15%;
    color: white;
    font-size: max(1.5em, 5vw);
}

.header-wrapper {
    position: relative;
}

.wall {
    width: 100%;
    height: 100%;
}
.social-part .fa{
    padding-right:20px;
    margin-right: 20px;
    max-width: 30px;
}

.brand-name {
    color:black !important;
    text-decoration: none !important;
}

.social-part .logo-nav {
    max-width: 30px;
    -webkit-filter: invert(40%) sepia(0%) saturate(0%) hue-rotate(0deg) ;
            filter: invert(40%) sepia(0%) saturate(0%) hue-rotate(0deg) ;
}

.brand {
    padding-top: 1em;
}

.social-part .logo-nav:hover {
    -webkit-filter: invert(0%) sepia(0%) saturate(50%) hue-rotate(86deg) ;
            filter: invert(0%) sepia(0%) saturate(50%) hue-rotate(86deg) ;
}
.social-part .logo-git {
    padding-left: .1em;
    padding-top: .2em;
    -webkit-filter: invert(40%) sepia(0%) saturate(0%) hue-rotate(0deg) ;
            filter: invert(40%) sepia(0%) saturate(0%) hue-rotate(0deg) ;
}

.social-part .logo-git:hover {
    -webkit-filter: invert(0%) sepia(0%) saturate(50%) hue-rotate(86deg) ;
            filter: invert(0%) sepia(0%) saturate(50%) hue-rotate(86deg) ;
}

.home {
    font-family: "Georgia";
}

.home .home-nav {
    color : black !important;
}

.container {
    padding-top: 2em;
    font-size: min(max(1vw, 1px), 1px);
}

.title {
    font-size: 1.2em;
}

.about {
    padding-bottom: 3vh;
}

.buttons-container {
    margin-bottom: 3vh;
    display: flex;
}

.proj-btn {
    background-color: rgb(85, 122, 149, 0.3) !important;
    margin-bottom: 1vh;
}

.thoughts-btn {
    margin-left: 1vw;
    background-color: rgb(85, 122, 149, 0.3) !important;
    margin-bottom: 1vh;
}

.proj-btn:hover,
.thoughts-btn:hover {
    color: black !important;
    background-color: rgb(85, 122, 149) !important;
}

.card {
    border: 0em;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.card-title {
    font-size: 1em;
    position: relative;
    margin-right: 7.5vw;
}

.company-logo {
    float: right;
    right: 20px;
    position: absolute;
    height: max(5vw, 40px);
    width: max(5vw, 40px);
}

.comcast-logo {
    float: right;
    right: 20px;
    position: absolute;
    height: max(4vw, 40px);
    width: max(6vw, 60px);
}

.card-text {
    font-size: 0.85em;
}

.skills-container {
    font-size: 0.85em;
}

.skill-title {
    font-size: 1.15em;
}

.skill-title-sub {
    font-size: 1.15em;
    margin-top: 2vh;
}

.sub {
    margin-bottom: 2vh;
}

.stars {
    margin-right: 15vw;
}
.foot {
    background-color: rgb(85, 122, 149, 0.3);
}

.logo {
    max-width: 40px;
    margin-right: 15px;
}

.logo:hover {
    -webkit-filter: invert(48%) sepia(0%) saturate(0%) hue-rotate(86deg) ;
            filter: invert(48%) sepia(0%) saturate(0%) hue-rotate(86deg) ;
}

.projects {
    font-family: 'Georgia';
}

.projects .projects-nav {
    color: black !important;
}

.container {
    font-size: min(max(1.5vw, 18px), 28px);
}


.project-card {
    background-color: rgb(85, 122, 149, 0.1);
}

.card-text {
    font-size: .85em;
}

.project-photo {
    margin-top: 20px;
    max-width: 100%;
}

.phone-photo {
    display: block;
    margin-top: 20px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-height: 50vh;
}

.github-logo {
    margin-top: 10px;
    height: auto;
    width: auto;
    max-width: 70px;
}

.github-logo:hover {
    margin-top: 10px;
    height: auto;
    width: auto;
    max-width: 70px;
    opacity: 0.5;
}

.amazon-stars {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    margin-top: 20px;
    max-height: 40vh;
}

.hive-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 60%;
    margin-top: 20px;
    max-height: 30vh;
}

.overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
}
.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 50%;
  position: relative;
  transition: all 5s ease-in-out;
}


.about-popup {
  margin: 5vh auto;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  background: #fff;
  border-radius: 5px;
  width: 80%;
  position: relative;
  transition: all 5s ease-in-out;
}

.about-popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.about-content {
  font-size: max(16px, 1.5vw);
}
.about-popup .close:hover {
  cursor: pointer;
  color: #000;
}
.about-popup .content {
  max-height: 70%;
  overflow: auto;
}

.title {
  padding-top: 25px;
  padding-bottom: 10px;
}

.about-title {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: max(20px, 2vw);
}

.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  cursor: pointer;
  color: #000;
}
.popup .content {
  max-height: 70%;
  overflow: auto;
}

.popup-btn {
  background-color: rgb(85, 122, 149, 0.2) !important;
  margin: 0 auto;
  display: block;
}

.btn-in-pop {
  width: 100%;
}

.popup-btn:hover {
  color: black !important;
  background-color: rgb(85, 122, 149) !important;
}

.popup-btn:active {
  color: black !important;
  background-color: white !important;
}
 
@media screen and (max-width: 700px) {
  .popup {
    width: 70%;
  }
}
.wordle {
    font-family: 'Georgia';
}
.wordle-container {
    padding-bottom: 1vh;
}
.wordle .wordle-nav {
    color: black !important;
}

.wordle-table {
    border-collapse:separate; 
    border-spacing:0.5em;
    table-layout:fixed;
    width:100%;
}

.wordle-grid {
    max-width: 500px;
}

tr td {
    position: relative;
    border: 2px solid lightgray;
    height: max(12px, 5vw);
}


.about-button {
    background-color: rgb(85, 122, 149, 0.2) !important;
    margin: 0 auto;
    display: block;
    margin-bottom: 1vh;
    margin-top: 3vh;
  }
  
.about-button:hover {
    color: black !important;
    background-color: rgb(85, 122, 149) !important;
  }


.wordle-table > tbody > tr > td {
    font-weight: bold;
    text-align: center;
    font-size: max(14px, 3vw);
}

.keyboard-button {
    margin: max(0.2vw, 1px);
    font-weight: bold;
    font-size: max(12px, 1.5vw);
}

.keyboard {
    padding-bottom: 1vh;
}

.keyboard-row {
    margin: auto;
    height: 50px;
}

