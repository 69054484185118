.wordle {
    font-family: 'Georgia';
}
.wordle-container {
    padding-bottom: 1vh;
}
.wordle .wordle-nav {
    color: black !important;
}

.wordle-table {
    border-collapse:separate; 
    border-spacing:0.5em;
    table-layout:fixed;
    width:100%;
}

.wordle-grid {
    max-width: 500px;
}

tr td {
    position: relative;
    border: 2px solid lightgray;
    height: max(12px, 5vw);
}


.about-button {
    background-color: rgb(85, 122, 149, 0.2) !important;
    margin: 0 auto;
    display: block;
    margin-bottom: 1vh;
    margin-top: 3vh;
  }
  
.about-button:hover {
    color: black !important;
    background-color: rgb(85, 122, 149) !important;
  }


.wordle-table > tbody > tr > td {
    font-weight: bold;
    text-align: center;
    font-size: max(14px, 3vw);
}

.keyboard-button {
    margin: max(0.2vw, 1px);
    font-weight: bold;
    font-size: max(12px, 1.5vw);
}

.keyboard {
    padding-bottom: 1vh;
}

.keyboard-row {
    margin: auto;
    height: 50px;
}
